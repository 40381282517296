<template>
  <div class="p-3">
    <!-- {{ $props.empresaProp.cod_empresa }}
    | {{ $props.data }} |
    {{ $props.caixa && $props.caixa.cod_caixa ? $props.caixa.cod_caixa : "" }} |
    {{ $props.caixa && $props.caixa.cod_moto ? $props.caixa.cod_moto : "" }} |
    {{ $props.moto && $props.moto.cod_caixa ? $props.moto.cod_caixa : "" }} |
    {{ $props.moto && $props.moto.cod_moto ? $props.moto.cod_moto : "" }} -->
    <div class="row">
      <div class="col-12 p-4">
        <div class="card">
          <div class="card-body">
            
            <caixa-fechamento
              :caixaTipo="caixaTipo"
              :EmpresaAtiva="$props.empresaProp"
              :withData="false"
              :dataInicial="$props.data"
              @updateCaixas="$emit('updateCaixas')"
            />
          </div>
        </div>
      </div>
      <div class="col-12 p-4">
        <div class="card">
          <div class="card-body">
            <saidas
              :caixaTipo="caixaTipo"
              :EmpresaAtiva="empresaProp"
              :withData="false"
              :dataInicial="dataSel"
              @updateCaixas="$emit('updateCaixas')"
            />
          </div>
        </div>
      </div>
    </div>

    <hr />
  </div>
</template>

<script>
import CaixaFechamento from "../../components/Caixas/CaixaFechamento.vue";
import Saidas from "../../components/Caixas/Saidas.vue";
import moment from "moment";

const dataDef = moment().add(parseInt(moment().format("HH")) < 8 ? -1 : 0, "d");

export default {
  components: {
    Saidas,
    CaixaFechamento,
  },
  props: {
    data: Date,
    caixa: Object,
    moto: Object,
    empresaProp: Object,
  },

  data() {
    return {
      dataSel: dataDef.format("YYYY-MM-DD"),
      user: this.$store.state.auth.user,
      caixaAtivo: this.$store.state.caixa_ativo,
      empresa: this.$store.state.empresa_ativa,
      caixaTipo: {},
    };
  },

  mounted() {
    this.dataSel = moment(this.$props.data).format("YYYY-MM-DD");
    // console.log("caixa-user mounted", this.$props);
    this.caixaAtivo = this.$props.caixa || this.$props.moto;

    this.changeCaixa();
  },
  watch: {
    "$props.data": function () {
      this.dataSel = moment(this.$props.data).format("YYYY-MM-DD");
      // console.log("caixa-user data sel changed 2");
      this.caixaAtivo = this.$props.caixa || this.$props.moto;
      this.changeCaixa();
    },
    dataSel: function () {
      // console.log("caixa-user data sel changed", this.dataSel);
      this.changeCaixa();
    },
    "$store.state.caixa_ativo": function () {
      this.caixaAtivo = this.$store.state.caixa_ativo;
      // console.log('mudou cx ali',this.caixaAtivo)

      this.changeCaixa();
    },
    caixa: {
      deep: true,
      handler() {
        this.caixaAtivo = this.caixa;
        //if(!)
        // console.log('mudou cx aqui',this.caixa)
        this.changeCaixa();
      },
    },
    $props: {
      deep: true,
      handler() {
        // console.log("mudou cx props", this.$props);
        this.changeCaixa();
      },
    },
    empresaProp: {
      deep: true,
      handler() {
        this.empresa = this.empresaProp;
        // console.log("mudou cx aqui", this.caixa);
        this.changeCaixa();
      },
    },
  },
  methods: {
    changeCaixa() {
      // console.log("change cx", this.caixaAtivo);
      if (!this.caixaAtivo) {
        this.caixaAtivo = this.$store.state.caixa_ativo;
       }
      if (this.caixaAtivo) {
        this.caixaTipo = {
          tipo:
            this.caixaAtivo.cod_moto || this.caixaAtivo.cod_moto == 0
              ? "moto"
              : "caixa",
          cod: this.caixaAtivo.cod_caixa
            ? this.caixaAtivo.cod_caixa
            : this.caixaAtivo.cod_moto,
          desc: this.caixaAtivo.nome,
        };
      }


    },
  },
};
</script>

<style></style>
