<template>
  <div class="cxSel">
    <div class="titulo">  
      
      <i
        class="fa fa-cash-register"
        :class="{ 'fa-motorcycle': isMoto }"
      />
      {{ isMoto && obj.moto ? obj.moto : obj.nome? obj.nome:'' }}
    </div>
    <div class="sts">
      <div v-if="obj.informados && obj.informados.length>0">
        <i class="fa fa-check" /> Informado
      </div>
      <div v-if="obj.conferencias && obj.conferencias.length>0">
        <div :class="{'text-warning':obj.conferencias.find(x=>x.situacao!=1), 'text-success':obj.conferencias.find(x=>x.situacao==1)}">
          <i class="fa fa-check-double" /> Conferencia {{obj.conferencias.find(x=>x.situacao==1)?'OK':'Com pendencia/erro'}}
        </div>
      </div>
      <div v-if="!obj.conferencias || obj.conferencias.length<=0" class="text-danger">
          <i class="fa fa-times" /> Não Conferido

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: Object,
    isMoto: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0
    }
  },
  mounted(){
    // console.log(this.obj)
  },
  data(){
    return{
      open:false,
    }
  },
  
};
</script>


<style lang="sass" scoped>
@import "@/assets/scss/caixas.scss"
</style>